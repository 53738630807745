import { React, useState } from "react";
import { useNavigate } from "react-router-dom";

// button used for left side of a merged button
const LeftHoverButton = ({ icon, text, whereToNavigate }) => {
  const navigate = useNavigate();

  const [isButtonHovered, setIsButtonHovered] = useState(false);

  return (
    <button
      className={`button is-rounded is-responsive is-medium is-dark is-inverted hover-button
                ${isButtonHovered ? "has-background-link-light" : ""}`}
      onMouseEnter={() => setIsButtonHovered(true)}
      onMouseLeave={() => setIsButtonHovered(false)}
      onClick={() => navigate(`/${whereToNavigate}`)}
    >
      <span className="icon">
        <i className={`fas ${icon}`}></i>
      </span>
      <span>{text}</span>
    </button>
  );
};

export default LeftHoverButton;
