import { React, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormFieldInput from "./FormFieldInput";
import FormFieldSelect from "./FormFieldSelect";

const FormOnePerson = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [inputValue, setInputValue] = useState("");
  const [birthdayValue, setBirthdayValue] = useState("");
  const [genderValue, setGenderValue] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    params.name = inputValue;
    params.birthdate = birthdayValue;
    params.gender = genderValue;
    navigate(`/melody-${inputValue}-${birthdayValue}-${genderValue}`);
  };

  const checkIfButtonDisabled = () => {
    if (
      inputValue === "" ||
      birthdayValue === "" ||
      birthdayValue > "2100-12-31" ||
      birthdayValue < "1900-01-01" ||
      genderValue === "Select Gender" ||
      genderValue === ""
    ) {
      return (
        <div className="field has-text-centered">
          <button
            className="button is-responsive is-large is-light is-rounded"
            onClick={onSubmit}
            disabled
          >
            GENERATE
          </button>
        </div>
      );
    } else if (
      inputValue !== "" &&
      birthdayValue !== "" &&
      genderValue !== ""
    ) {
      return (
        <div className="field has-text-centered">
          <button
            className="button is-responsive is-large is-light  is-rounded"
            onClick={onSubmit}
          >
            GENERATE
          </button>
        </div>
      );
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <FormFieldInput
        labelText={"FIRST NAME"}
        inputType={"text"}
        placeholder={"User's First Name"}
        value={inputValue}
        onChangeFunction={(e) =>
          setInputValue(e.target.value.replace(/[^a-z]/gi, "").toUpperCase())
        }
      />
      <FormFieldInput
        labelText={"BIRTHDATE"}
        inputType={"date"}
        max={"2100-12-31"}
        min={"1900-01-01"}
        placeholder={"User's Birthdate"}
        value={birthdayValue}
        onChangeFunction={(e) => setBirthdayValue(e.target.value)}
      />
      <FormFieldSelect
        onChangeFunction={(e) => setGenderValue(e.target.value)}
      />
      &nbsp;
      {checkIfButtonDisabled()}
    </form>
  );
};

export default FormOnePerson;
