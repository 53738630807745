import React from "react";

const FormFieldSelect = ({ onChangeFunction }) => {
  return (
    <div className="field">
      <label className="label is-large">GENDER</label>
      <div className="control">
        <div className="select is-medium is-dark is-fullwidth is-responsive is-rounded">
          <select onChange={onChangeFunction} className="form-select">
            <option value={""}>Select Gender</option>
            <option>Female</option>
            <option>Male</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default FormFieldSelect;
