import React from "react";
import PageTemplate from "../../Components/PageTemplate";
import FormTwoPeople from "../../Components/Form/FormTwoPeople";
import TypeWriter from "../../Components/TypeWriter";

const GenerateTwoPeople = () => {
  const twoPersonContent = () => {
    return (
      <div>
        <div className="title">
          <TypeWriter text={"GENERATE"} length={"short"} />
        </div>
        <FormTwoPeople />
      </div>
    );
  };

  return (
    <PageTemplate
      content={twoPersonContent()}
      currentPage={"generate-one-person"}
    />
  );
};

export default GenerateTwoPeople;
