import React from "react";

const ThemeDropdown = ({ onChangeFunction }) => {
  return (
    <div className="select is-rounded">
      <select onChange={onChangeFunction}>
        <option selected value="birthday">
          Birthday
        </option>
        <option value="thankyou">Thank You</option>
        <option value="anniversary">Anniversary</option>
        <option value="graduation">Graduation</option>
        <option value="valentine">Valentine's Day</option>
      </select>
      <br />
    </div>
  );
};

export default ThemeDropdown;
