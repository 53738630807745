import React from "react";

const ErrorMessage = ({ message }) => {
  return (
    <div className="field">
      <label className="label label-error">{message}</label>
    </div>
  );
};

export default ErrorMessage;
