import React from "react";

const RoundedIconButtonNoNav = ({
  icon,
  text,
  onClickFunction,
  isDisabledBool,
}) => {
  return (
    <button
      className={`button is-light is-responsive is-medium is-rounded`}
      onClick={onClickFunction}
      disabled={isDisabledBool}
    >
      <span className="icon">
        <i className={`fas ${icon}`}></i>
      </span>
      <span>{text}</span>
    </button>
  );
};

export default RoundedIconButtonNoNav;
