import React from "react";
import MessageWithDelete from "./MessageWithDelete";
import RoundedIconButton from "../Buttons/RoundedIconButton";

const CardsIntro = ({ deleteOnClick }) => {
  const messageContent = () => {
    return (
      <div className="has-text-centered">
        <div className="field valentine">
          We are <strong>SO</strong> excited to share that you can now send{" "}
          <strong>virtual cards</strong> with us! Show your loved ones how much
          you care with a <strong>free</strong> virtual card! Happy melody
          making!
        </div>
        <RoundedIconButton
          icon={"fa-circle-arrow-right"}
          text={"Send A Card"}
          whereToNavigate={"cards"}
        />
      </div>
    );
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <MessageWithDelete
          message={"Virtual Cards!"}
          messageContent={messageContent()}
          deleteOnClick={deleteOnClick}
        />
      </div>
    </div>
  );
};

export default CardsIntro;
