import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

import CompletedForm from "../CompletedForm";
import NameField from "../Form/E-Cards/NameField";
import ErrorMessage from "./E-Cards/ErrorMessage";
import TextBox from "./E-Cards/TextBox";
import SendEmailButton from "./E-Cards/SendEmailButton";
import Radio from "./E-Cards/Radio";

const CardForm = ({ template }) => {
  const form = useRef();
  const templateValue = template;

  // initial values we need
  const [nameValue, setNameValue] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [confirmSender, setConfirmSender] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [confirmReceiver, setConfirmReceiver] = useState("");
  const [birthdateValue, setBirthdateValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [genderValue, setGenderValue] = useState("Male");

  // values that we need to check for
  const [isEmptyMessage, setIsEmptyMessage] = useState(false);
  const [isInvalidEmailMessage, setIsInvalidEmailMessage] = useState(false);
  const [isInvalidSenderMessage, setIsInvalidSenderMessage] = useState(false);
  const [doesNotMatchMessage, setDoesNotMatchMessage] = useState(false);
  const [doesNotMatchSender, setDoesNotMatchSender] = useState(false);
  const [isInvalidBdayMessage, setIsInvalidBdayMessage] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // valid email regex
  const validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const validBirthdayRegex =
    /^(?:19|20)\d\d[-](?:0[1-9]|1[012])[-](?:0[1-9]|[12]\d|3[01])$/;

  // check if the email is valid
  const checkEmailValid = (email) => {
    return validEmailRegex.test(email);
  };

  // check if birthdate is valid
  const checkBirthdateValid = (bday) => {
    return (
      validBirthdayRegex.test(bday) &&
      bday >= "1900-01-01" &&
      bday <= "2100-12-31"
    );
  };

  // check if two emails match
  const checkIfEmailsMatch = (email1, email2) => {
    return email1 === email2;
  };

  // get proper templates
  const getEmailTemplate = (templateVal) => {
    let template = "";
    switch (templateVal) {
      case "birthday-balloons":
        template = "template_mp2lunq";
        break;
      case "bubble-letter":
        template = "template_4zk8565";
        break;
      case "multi-colour":
        template = "template_sfywin1";
        break;
      case "stars":
        template = "template_l07y49k";
        break;
      case "cursive":
        template = "template_qaschbf";
        break;
      case "flowers":
        template = "template_fx8reuf";
        break;
      case "part-of-my-life":
        template = "template_j1vveqf";
        break;
      case "simple-design":
        template = "template_i1scs0v";
        break;
      case "thankyou1":
        template = "template_9tug07b";
        break;
      case "thankyou2":
        template = "template_18io0ne";
        break;
      case "thankyou3":
        template = "template_ypbhr6n";
        break;
      case "thankyou4":
        template = "template_pjoqhlj";
        break;
      case "anniversary1":
        template = "template_2radxti";
        break;
      case "anniversary2":
        template = "template_55do304";
        break;
      case "anniversary3":
        template = "template_2j9aifk";
        break;
      case "anniversary4":
        template = "template_iunc60c";
        break;
      case "graduation_1":
        template = "template_rwgceze";
        break;
      case "graduation_2":
        template = "template_2kg7tyf";
        break;
      case "graduation_3":
        template = "template_1u5ekub";
        break;
      case "graduation_4":
        template = "template_lbht0ag";
        break;
      case "holiday1":
        template = "template_i6zusd2";
        break;
      case "holiday2":
        template = "template_0e0zu0j";
        break;
      case "holiday3":
        template = "template_74ow2ep";
        break;
      case "holiday4":
        template = "template_lktptjp";
        break;
      case "christmas1":
        template = "template_5azas9h";
        break;
      case "christmas2":
        template = "template_lxd9knr";
        break;
      case "christmas3":
        template = "template_4zgb5i4";
        break;
      case "christmas4":
        template = "template_yb4vb3g";
        break;
      case "vday1":
        template = "template_viisq1b";
        break;
      case "vday2":
        template = "template_4naaslc";
        break;
      case "vday3":
        template = "template_goyh1tq";
        break;
      case "vday4":
        template = "template_d7mnz0p";
        break;
      default:
        template = "template_mp2lunq";
    }

    return template;
  };

  // on submission of form
  const onSubmit = (e) => {
    e.preventDefault();

    if (messageValue === "") {
      setIsEmptyMessage(true);
    }

    // set error messages
    !checkEmailValid(receiverEmail) && setIsInvalidEmailMessage(true);
    !checkEmailValid(senderEmail) && setIsInvalidSenderMessage(true);
    !checkIfEmailsMatch(senderEmail, confirmSender) &&
      setDoesNotMatchSender(true);
    !checkIfEmailsMatch(receiverEmail, confirmReceiver) &&
      setDoesNotMatchMessage(true);
    !checkBirthdateValid(birthdateValue) && setIsInvalidBdayMessage(true);

    // if everything is good
    if (
      checkEmailValid(receiverEmail) &&
      checkIfEmailsMatch(receiverEmail, confirmReceiver) &&
      messageValue !== "" &&
      checkEmailValid(senderEmail) &&
      checkIfEmailsMatch(senderEmail, confirmSender) &&
      checkBirthdateValid(birthdateValue)
    ) {
      emailjs
        .sendForm(
          "service_k4bzh0d",
          getEmailTemplate(templateValue),
          form.current,
          "qBm61RY0T7eYF8pKD"
        )
        .then(
          (result) => {
            console.log(result.text);
            setIsSubmitted(true);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      console.log("not yet");
    }
  };

  const cardForm = () => {
    return (
      <form ref={form} onSubmit={onSubmit}>
        <div className="email-field has-text-centered">
          <NameField
            label={"Your Name *"}
            inputType={"text"}
            placeholder={"Your Name"}
            maxLength={30}
            value={nameValue}
            name={"from_name"}
            onChangeFunction={(e) => {
              setNameValue(e.target.value);
            }}
            icon="fas fa-pen"
          />
          <NameField
            label={"Recipient's Email *"}
            inputType={"email"}
            placeholder={"Recipient's Email"}
            value={receiverEmail}
            name={"to_email"}
            onChangeFunction={(e) => {
              setReceiverEmail(e.target.value);
              !checkEmailValid(e.target.value)
                ? setIsInvalidEmailMessage(true)
                : setIsInvalidEmailMessage(false);
            }}
            icon="fas fa-envelope"
          />
          {isInvalidEmailMessage && (
            <ErrorMessage message={"Email is invalid!"} />
          )}
          <NameField
            label={"Confirm Recipient's Email *"}
            inputType={"email"}
            placeholder={"Recipient's Email"}
            value={confirmReceiver}
            onChangeFunction={(e) => {
              !checkIfEmailsMatch(receiverEmail, e.target.value)
                ? setDoesNotMatchMessage(true)
                : setDoesNotMatchMessage(false);
              setConfirmReceiver(e.target.value);
            }}
            icon={"fas fa-envelope"}
          />
          {doesNotMatchMessage && (
            <ErrorMessage message={"Emails entered do not match!"} />
          )}

          {/* SENDER EMAIL */}
          <NameField
            label={"Your Email *"}
            inputType={"email"}
            placeholder={"Your Email - Won't be shown to recipient"}
            value={senderEmail}
            name={"bcc_email"}
            onChangeFunction={(e) => {
              setSenderEmail(e.target.value);
              !checkEmailValid(e.target.value)
                ? setIsInvalidSenderMessage(true)
                : setIsInvalidSenderMessage(false);
            }}
            icon={"fas fa-envelope"}
          />
          {isInvalidSenderMessage && (
            <ErrorMessage message={"Email is invalid!"} />
          )}
          <NameField
            label={"Confirm Your Email *"}
            inputType={"email"}
            placeholder={"Your Email - Won't be shown to recipient"}
            value={confirmSender}
            onChangeFunction={(e) => {
              !checkIfEmailsMatch(senderEmail, e.target.value)
                ? setDoesNotMatchSender(true)
                : setDoesNotMatchSender(false);
              setConfirmSender(e.target.value);
            }}
            icon={"fas fa-envelope"}
          />
          {doesNotMatchSender && (
            <ErrorMessage message={"Emails entered do not match!"} />
          )}
          <NameField
            label={"Date of Celebration *"}
            inputType={"tel"}
            maxLength={10}
            placeholder={"YYYY-MM-DD"}
            value={birthdateValue}
            name={"bday_value"}
            onKeyUpFunction={(e) => {
              if (
                e.key !== "Backspace" &&
                (e.target.value.length === 4 || e.target.value.length === 7)
              ) {
                e.target.value += "-";
              }
            }}
            onChangeFunction={(e) => {
              setBirthdateValue(e.target.value);
            }}
            icon={"fa-solid fa-cake-candles"}
          />
          {isInvalidBdayMessage && (
            <ErrorMessage
              message={
                "Please enter a date in the format YYYY-MM-DD (include dashes)"
              }
            />
          )}
          <Radio
            text1={"Male"}
            value1={"Male"}
            text2={"Female"}
            value2={"Female"}
            name={"gender"}
            label={"Recipient's Gender *"}
            onClickFunction={(e) => {
              setGenderValue(e.target.value);
            }}
          />
          <TextBox
            label={"Personalized message *"}
            name={"message"}
            value={messageValue}
            placeholder={"Put your personalized message here!"}
            onChangeFunction={(e) => {
              console.log(e.target.value);
              setMessageValue(e.target.value);
            }}
          />
          {isEmptyMessage && (
            <ErrorMessage
              message={"Please enter a message in order to send your card!"}
            />
          )}
          <SendEmailButton
            text={"Send Your Card!"}
            icon={"fas fa-paper-plane"}
          />
        </div>
      </form>
    );
  };

  return isSubmitted ? <CompletedForm /> : cardForm();
};

export default CardForm;
