import React from "react";

const FormFieldInput = ({
  labelText,
  inputType,
  placeholder,
  value,
  onChangeFunction,
  max = "",
  min = "",
}) => {
  return (
    <div className="field">
      <label className="label is-large">{labelText}</label>
      <div className="control">
        <input
          className="input is-medium is-dark is-responsive is-rounded form-select"
          type={inputType}
          placeholder={placeholder}
          max={max}
          min={min}
          value={value}
          onChange={onChangeFunction}
        />
      </div>
    </div>
  );
};

export default FormFieldInput;
