import React from "react";

const Radio = ({
  text1,
  text2,
  name,
  label,
  onClickFunction,
  value1,
  value2,
}) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <label className="radio">
          <input
            type="radio"
            name={name}
            value={value1}
            defaultChecked
            onClick={onClickFunction}
          />
          {text1}
        </label>
        <label className="radio">
          <input
            type="radio"
            name={name}
            value={value2}
            onClick={onClickFunction}
          />
          {text2}
        </label>
      </div>
    </div>
  );
};

export default Radio;
