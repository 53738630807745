import React from "react";

const ShareIcons = ({ iconName, onClickFunction }) => {
  return (
    <span className="icon is-info clickable is-large">
      <a onClick={onClickFunction} target="_blank">
        <i className={`${iconName} fa-2x`}></i>
      </a>
    </span>
  );
};

export default ShareIcons;
