import React from "react";
import PageTemplate from "../../Components/PageTemplate";
import FormOnePerson from "../../Components/Form/FormOnePerson";
import TypeWriter from "../../Components/TypeWriter";

const GenerateOnePerson = () => {
  const onePersonContent = () => {
    return (
      <div>
        <div className="title">
          <TypeWriter text={"GENERATE"} length={"short"} />
        </div>
        <FormOnePerson />
      </div>
    );
  };

  return (
    <PageTemplate
      content={onePersonContent()}
      currentPage={"generate-one-person"}
    />
  );
};

export default GenerateOnePerson;
