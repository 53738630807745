export const getFirstDigitPercentage = (m1, f1) => {
  let firstDigitPercentage = 0;
  if (m1 === "0") {
    switch (f1) {
      case "0":
      case "1":
      case "3":
        firstDigitPercentage += 12;
        break;
      case "2":
        firstDigitPercentage += 4;
        break;
      default:
        firstDigitPercentage = 0;
    }
  } else if (m1 === "1") {
    switch (f1) {
      case "0":
        firstDigitPercentage += 8;
        break;
      case "1":
      case "2":
        firstDigitPercentage += 12;
        break;
      case "3":
        firstDigitPercentage += 4;
        break;
      default:
        firstDigitPercentage = 0;
    }
  } else if (m1 === "2") {
    switch (f1) {
      case "0":
      case "2":
      case "3":
        firstDigitPercentage += 12;
        break;
      case "1":
        firstDigitPercentage += 8;
        break;
      default:
        firstDigitPercentage = 0;
    }
  } else if (m1 === "3") {
    switch (f1) {
      case "0":
        firstDigitPercentage += 4;
        break;
      case "1":
      case "3":
        firstDigitPercentage += 12;
        break;
      case "2":
        firstDigitPercentage += 8;
        break;
      default:
        firstDigitPercentage = 0;
    }
  }

  return firstDigitPercentage;
};

export const getSecondFourthSeventhEigthPercentage = (d1, d2) => {
  let percentage = 0;
  if (d1 === "0") {
    switch (d2) {
      case "0":
      case "2":
      case "4":
      case "5":
      case "7":
      case "9":
        percentage += 12;
        break;
      case "1":
      case "6":
        percentage += 4;
        break;
      case "3":
        percentage += 8;
        break;
      case "8":
        percentage += 10;
        break;
      default:
        percentage = 0;
    }
  } else if (d1 === "1") {
    switch (d2) {
      case "0":
      case "2":
      case "7":
        percentage += 4;
        break;
      case "1":
      case "3":
      case "5":
      case "6":
      case "8":
        percentage += 12;
        break;
      case "4":
      case "9":
        percentage += 10;
        break;
      default:
        percentage = 0;
    }
  } else if (d1 === "2") {
    switch (d2) {
      case "0":
      case "2":
      case "4":
      case "6":
      case "7":
      case "9":
        percentage += 12;
        break;
      case "1":
      case "3":
        percentage += 4;
        break;
      case "5":
        percentage += 8;
        break;
      case "8":
        percentage += 6;
        break;
      default:
        percentage = 0;
    }
  } else if (d1 === "3") {
    switch (d2) {
      case "0":
      case "6":
        percentage += 8;
        break;
      case "1":
      case "3":
      case "5":
      case "7":
      case "8":
        percentage += 12;
        break;
      case "2":
      case "4":
        percentage += 4;
        break;
      case "9":
        percentage += 6;
        break;
      default:
        percentage = 0;
    }
  } else if (d1 === "4") {
    switch (d2) {
      case "0":
      case "2":
      case "4":
      case "6":
      case "8":
      case "9":
        percentage += 12;
        break;
      case "1":
      case "7":
        percentage += 8;
        break;
      case "3":
      case "5":
        percentage += 4;
        break;
      default:
        percentage = 0;
    }
  } else if (d1 === "5") {
    switch (d2) {
      case "0":
      case "1":
      case "3":
      case "5":
      case "7":
      case "9":
        percentage += 12;
        break;
      case "2":
      case "8":
        percentage += 8;
        break;
      case "4":
      case "6":
        percentage += 4;
        break;
      default:
        percentage = 0;
    }
  } else if (d1 === "6") {
    switch (d2) {
      case "0":
      case "5":
      case "7":
        percentage += 4;
        break;
      case "1":
      case "2":
      case "4":
      case "6":
      case "8":
        percentage += 12;
        break;
      case "3":
      case "9":
        percentage += 8;
        break;
      default:
        percentage = 0;
    }
  } else if (d1 === "7") {
    switch (d2) {
      case "0":
      case "2":
      case "3":
      case "5":
      case "7":
      case "9":
        percentage += 12;
        break;
      case "1":
        percentage += 6;
        break;
      case "4":
        percentage += 8;
        break;
      case "6":
      case "8":
        percentage += 4;
        break;
      default:
        percentage = 0;
    }
  } else if (d1 === "8") {
    switch (d2) {
      case "0":
      case "2":
        percentage += 6;
        break;
      case "1":
      case "3":
      case "4":
      case "6":
      case "8":
        percentage += 12;
        break;
      case "5":
        percentage += 8;
        break;
      case "7":
      case "9":
        percentage += 4;
        break;
      default:
        percentage = 0;
    }
  } else if (d1 === "9") {
    switch (d2) {
      case "0":
      case "2":
      case "4":
      case "5":
      case "7":
      case "9":
        percentage += 12;
        break;
      case "1":
      case "3":
        percentage += 6;
        break;
      case "6":
        percentage += 8;
        break;
      case "8":
        percentage += 4;
        break;
      default:
        percentage = 0;
    }
  }

  return percentage;
};

export const getThirdDigitPercentage = (m3, f3) => {
  let percentage = 0;
  if (m3 === "0") {
    switch (f3) {
      case "0":
      case "1":
        percentage += 12;
        break;
      default:
        percentage = 0;
    }
  } else if (m3 === "1") {
    switch (f3) {
      case "0":
        percentage += 12;
        break;
      case "1":
        percentage += 8;
        break;
      default:
        percentage = 0;
    }
  }

  return percentage;
};

export const getFifthDigitPercentage = (m5, f5) => {
  let percentage = 0;
  if (m5 === "1") {
    switch (f5) {
      case "1":
        percentage += 12;
        break;
      case "2":
        percentage += 8;
        break;
      default:
        percentage = 0;
    }
  } else if (m5 === "2") {
    switch (f5) {
      case "1":
        percentage += 8;
        break;
      case "2":
        percentage += 12;
        break;
      default:
        percentage = 0;
    }
  }

  return percentage;
};

export const getSixthDigitPercentage = (m6, f6) => {
  let percentage = 0;
  if (m6 === "9") {
    switch (f6) {
      case "9":
        percentage += 12;
        break;
      case "0":
        percentage += 8;
        break;
      default:
        percentage = 0;
    }
  } else if (m6 === "0") {
    switch (f6) {
      case "9":
        percentage += 8;
        break;
      case "0":
        percentage += 12;
        break;
      default:
        percentage = 0;
    }
  }

  return percentage;
};

export const checkSamAndLola = (
  l,
  ldate,
  lmonth,
  lyear,
  lgender,
  s,
  sdate,
  smonth,
  syear,
  sgender
) => {
  if (
    (l === "LOLA" &&
      ldate === "23" &&
      lmonth === "06" &&
      lyear === "2000" &&
      lgender === "Female" &&
      s === "SAM" &&
      sdate === "03" &&
      smonth === "05" &&
      syear === "2003" &&
      sgender === "Male") ||
    (l === "SAM" &&
      ldate === "03" &&
      lmonth === "05" &&
      lyear === "2003" &&
      lgender === "Male" &&
      s === "LOLA" &&
      sdate === "23" &&
      smonth === "06" &&
      syear === "2000" &&
      sgender === "Female")
  ) {
    return true;
  }

  return false;
};

export const checkRichardAndLola = (
  l,
  ldate,
  lmonth,
  lyear,
  lgender,
  s,
  sdate,
  smonth,
  syear,
  sgender
) => {
  if (
    (l === "LOLA" &&
      ldate === "23" &&
      lmonth === "06" &&
      lyear === "2000" &&
      lgender === "Female" &&
      s === "RICHARD" &&
      sdate === "22" &&
      smonth === "03" &&
      syear === "2002" &&
      sgender === "Male") ||
    (l === "RICHARD" &&
      ldate === "22" &&
      lmonth === "03" &&
      lyear === "2002" &&
      lgender === "Male" &&
      s === "LOLA" &&
      sdate === "23" &&
      smonth === "06" &&
      syear === "2000" &&
      sgender === "Female")
  ) {
    return true;
  } else {
    return false;
  }
};
