import React from "react";

const InstrumentDropdown = ({ onChangeFunction }) => {
  return (
    <div className="control has-icons-left">
      <div className="select is-fullwidth is-rounded is-multiple">
        <select className="is-light" onChange={onChangeFunction}>
          <option value="acoustic_grand_piano">Acoustic Grand Piano</option>
          <option value="acoustic_guitar_nylon">Acoustic Guitar</option>
          <option value="electric_guitar_clean">Electric Guitar</option>
          <option value="flute">Flute</option>
          {/* <option value="glockenspiel">Glockenspiel</option> */}
          <option value="orchestral_harp">Harp</option>
          <option value="marimba">Marimba</option>
          {/* <option value="music_box">Music Box</option> */}
          {/* <option value="trombone">Trombone</option> */}
          <option value="trumpet">Trumpet</option>
          <option value="violin">Violin</option>
          <option value="vibraphone">Vibraphone</option>
        </select>
        <div className="icon is-medium is-left">
          <span className="icon has-text-dark">
            <i className="fa-solid fa-music"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default InstrumentDropdown;
