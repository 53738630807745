import { React, useState } from "react";
import PageTemplate from "../../Components/PageTemplate";
import InstrumentDropdown from "../../Components/InstrumentDropdown";
import PlayMusicOne from "../../Components/PlayMusic/PlayMusicOne";
import TypeWriter from "../../Components/TypeWriter";

const MelodyOneUser = () => {
  const [instrumentValue, setInstrumentValue] = useState(
    "acoustic_grand_piano"
  );

  // get info from hyperlink
  let pathname = window.location.pathname;
  let new_pathname = pathname.split("-");
  new_pathname.shift();
  let firstName = new_pathname[0];
  let birthyear = new_pathname[1];
  let birthmonth = new_pathname[2];
  let birthdate = new_pathname[3];
  let gender = new_pathname[4];

  // const checkIsChristmas = () => {
  //   if (
  //     (birthmonth === "12" && birthdate === "25" && birthyear === "2023") ||
  //     (birthmonth === "01" && birthdate === "07" && birthyear === "2024")
  //   ) {
  //     return true;
  //   }

  //   return false;
  // };

  // const christmasContent = () => {
  //   return (
  //     <>
  //       <div className="title">
  //         <TypeWriter text={"MERRY CHRISTMAS!"} length={"long"} />
  //       </div>
  //       <p className="block">
  //         WOW! You've discovered our hidden gift! Pick an instrument from our
  //         dropdown and enjoy our <strong>secret holiday melody</strong>!
  //       </p>
  //       <p className="block">
  //         We wish you a very <strong>Merry Christmas</strong>, a safe, healthy
  //         and happy <strong>New Year</strong>, and all the best this holiday
  //         season!
  //       </p>
  //       <p className="block">
  //         If you're on a <strong>mobile device</strong> turn{" "}
  //         <strong>off</strong> silent mode to hear the Christmas melody!
  //       </p>
  //       <p className="block">
  //         <InstrumentDropdown
  //           onChangeFunction={(e) => setInstrumentValue(e.target.value)}
  //         />
  //       </p>
  //       <p className="block">
  //         <PlayMusicOne instrumentName={instrumentValue} />
  //       </p>
  //     </>
  //   );
  // };

  const melodyOneUserContent = () => {
    return (
      <>
        <div className="has-text-centered">
          <div className="title">
            <TypeWriter text={"RESULTS"} length={"short"} />
          </div>
          <p className="block">
            Hey <strong>{firstName}</strong>, here's the information we got from
            you!
          </p>
          <p className="block">
            Your <strong>birthdate</strong> is {birthyear}-{birthmonth}-
            {birthdate}, and your <strong>gender</strong> is{" "}
            {gender.toLowerCase()}
          </p>
          <p className="block">
            If on <strong>mobile</strong>, turn <strong>off</strong> silent mode
            to hear your melody!
          </p>
          <p className="block">
            <InstrumentDropdown
              onChangeFunction={(e) => setInstrumentValue(e.target.value)}
            />
          </p>
          <p className="block">
            <PlayMusicOne instrumentName={instrumentValue} />
          </p>
        </div>
      </>
    );
  };

  return <PageTemplate content={melodyOneUserContent()} currentPage={""} />;
};

export default MelodyOneUser;
