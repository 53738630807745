import React, { useState } from "react";
import PageTemplate from "../Components/PageTemplate";
import TypeWriter from "../Components/TypeWriter";
import RoundedIconButton from "../Components/Buttons/RoundedIconButton";
import CardsIntro from "../Components/Modal/CardsIntro";

const HomePage = () => {
  const [isModalActive, setIsModalActive] = useState(false);

  const homePageContent = () => {
    return (
      <div>
        {isModalActive && (
          <CardsIntro deleteOnClick={() => setIsModalActive(false)} />
        )}
        <div>
          <TypeWriter text="Welcome To..." length={"short"} />
        </div>
        <div className="title lineUp is-size-1 is-size-2-mobile">
          Birthday Melody Match!
        </div>
        <div className="buttons is-centered">
          <RoundedIconButton
            icon={"fa-circle-question"}
            text={"How To Use"}
            whereToNavigate={"instructions"}
          />
          <RoundedIconButton
            icon={"fa-circle-arrow-right"}
            text={"Get Started"}
            whereToNavigate={"generate"}
          />
        </div>
        <div
          className="button is-ghost is-responsive is-medium"
          onClick={() => {
            setIsModalActive(true);
          }}
        >
          Click Here - Virtual Cards!
        </div>
      </div>
    );
  };

  return <PageTemplate content={homePageContent()} currentPage={"home"} />;
};

export default HomePage;
