import React, { useState } from "react";
import PageTemplate from "../Components/PageTemplate";
import TypeWriter from "../Components/TypeWriter";
import ImageTiles from "../Components/ImageTiles";
import CardModal from "../Components/Modal/CardModal";
import ThemeDropdown from "../Components/ThemeDropdown";

const Cards = () => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [showCards, setShowCards] = useState("birthday");

  // const womensDay = () => {
  //   return (
  //     <>
  //       <ImageTiles
  //         onClickFunction={() => {
  //           setTemplateName("cursive");
  //           setIsModalActive(true);
  //         }}
  //         folderName={"womens_day"}
  //         imageSrc={"cursive.png"}
  //       />
  //       <ImageTiles
  //         onClickFunction={() => {
  //           setTemplateName("flowers");
  //           setIsModalActive(true);
  //         }}
  //         folderName={"womens_day"}
  //         imageSrc={"flowers.png"}
  //       />
  //       <ImageTiles
  //         onClickFunction={() => {
  //           setTemplateName("part-of-my-life");
  //           setIsModalActive(true);
  //         }}
  //         folderName={"womens_day"}
  //         imageSrc={"part-of-my-life.png"}
  //       />
  //       <ImageTiles
  //         onClickFunction={() => {
  //           setTemplateName("simple-design");
  //           setIsModalActive(true);
  //         }}
  //         folderName={"womens_day"}
  //         imageSrc={"simple-design.png"}
  //       />
  //     </>
  //   );
  // };

  const birthday = () => {
    return (
      <>
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("birthday-balloons");
            setIsModalActive(true);
          }}
          folderName={"birthday_cards"}
          imageSrc={"birthday-balloons.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("bubble-letter");
            setIsModalActive(true);
          }}
          folderName={"birthday_cards"}
          imageSrc={"bubble-letter.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("multi-colour");
            setIsModalActive(true);
          }}
          folderName={"birthday_cards"}
          imageSrc={"multi-colour.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("stars");
            setIsModalActive(true);
          }}
          folderName={"birthday_cards"}
          imageSrc={"stars.png"}
        />
      </>
    );
  };

  const thankyou = () => {
    return (
      <>
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("thankyou1");
            setIsModalActive(true);
          }}
          folderName={"thank_you"}
          imageSrc={"thankyou1.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("thankyou2");
            setIsModalActive(true);
          }}
          folderName={"thank_you"}
          imageSrc={"thankyou2.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("thankyou3");
            setIsModalActive(true);
          }}
          folderName={"thank_you"}
          imageSrc={"thankyou3.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("thankyou4");
            setIsModalActive(true);
          }}
          folderName={"thank_you"}
          imageSrc={"thankyou4.png"}
        />
      </>
    );
  };

  const anniversary = () => {
    return (
      <>
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("anniversary1");
            setIsModalActive(true);
          }}
          folderName={"anniversary"}
          imageSrc={"anniversary1.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("anniversary2");
            setIsModalActive(true);
          }}
          folderName={"anniversary"}
          imageSrc={"anniversary2.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("anniversary3");
            setIsModalActive(true);
          }}
          folderName={"anniversary"}
          imageSrc={"anniversary3.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("anniversary4");
            setIsModalActive(true);
          }}
          folderName={"anniversary"}
          imageSrc={"anniversary4.png"}
        />
      </>
    );
  };

  const graduation = () => {
    return (
      <>
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("graduation_1");
            setIsModalActive(true);
          }}
          folderName={"graduation"}
          imageSrc={"graduation_1.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("graduation_2");
            setIsModalActive(true);
          }}
          folderName={"graduation"}
          imageSrc={"graduation_2.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("graduation_3");
            setIsModalActive(true);
          }}
          folderName={"graduation"}
          imageSrc={"graduation_3.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("graduation_4");
            setIsModalActive(true);
          }}
          folderName={"graduation"}
          imageSrc={"graduation_4.png"}
        />
      </>
    );
  };

  const valentine = () => {
    return (
      <>
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("vday1");
            setIsModalActive(true);
          }}
          folderName={"valentines"}
          imageSrc={"vday1.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("vday2");
            setIsModalActive(true);
          }}
          folderName={"valentines"}
          imageSrc={"vday2.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("vday3");
            setIsModalActive(true);
          }}
          folderName={"valentines"}
          imageSrc={"vday3.png"}
        />
        <ImageTiles
          onClickFunction={() => {
            setTemplateName("vday4");
            setIsModalActive(true);
          }}
          folderName={"valentines"}
          imageSrc={"vday4.png"}
        />
      </>
    );
  };

  // const fathersday = () => {
  //   return (
  //     <>
  //       <ImageTiles
  //         onClickFunction={() => {
  //           setTemplateName("fathersday1");
  //           setIsModalActive(true);
  //         }}
  //         folderName={"fathers_day"}
  //         imageSrc={"fathersday1.png"}
  //       />
  //       <ImageTiles
  //         onClickFunction={() => {
  //           setTemplateName("fathersday2");
  //           setIsModalActive(true);
  //         }}
  //         folderName={"fathers_day"}
  //         imageSrc={"fathersday2.png"}
  //       />
  //       <ImageTiles
  //         onClickFunction={() => {
  //           setTemplateName("fathersday3");
  //           setIsModalActive(true);
  //         }}
  //         folderName={"fathers_day"}
  //         imageSrc={"fathersday3.png"}
  //       />
  //       <ImageTiles
  //         onClickFunction={() => {
  //           setTemplateName("fathersday4");
  //           setIsModalActive(true);
  //         }}
  //         folderName={"fathers_day"}
  //         imageSrc={"fathersday4.png"}
  //       />
  //     </>
  //   );
  // };

  // const mothersday = () => {
  //   return (
  //     <>
  //       <ImageTiles
  //         onClickFunction={() => {
  //           setTemplateName("mom1");
  //           setIsModalActive(true);
  //         }}
  //         folderName={"mothers_day"}
  //         imageSrc={"mothersday1.png"}
  //       />
  //       <ImageTiles
  //         onClickFunction={() => {
  //           setTemplateName("mom2");
  //           setIsModalActive(true);
  //         }}
  //         folderName={"mothers_day"}
  //         imageSrc={"mothersday2.png"}
  //       />
  //       <ImageTiles
  //         onClickFunction={() => {
  //           setTemplateName("mom3");
  //           setIsModalActive(true);
  //         }}
  //         folderName={"mothers_day"}
  //         imageSrc={"mothersday3.png"}
  //       />
  //       <ImageTiles
  //         onClickFunction={() => {
  //           setTemplateName("mom4");
  //           setIsModalActive(true);
  //         }}
  //         folderName={"mothers_day"}
  //         imageSrc={"mothersday4.png"}
  //       />
  //     </>
  //   );
  // };

  const showProperContent = () => {
    if (showCards === "birthday") {
      return birthday();
    } else if (showCards === "thankyou") {
      return thankyou();
    } else if (showCards === "anniversary") {
      return anniversary();
    } else if (showCards === "graduation") {
      return graduation();
    } else if (showCards === "valentine") {
      return valentine();
    }
  };

  const cardContent = () => {
    return (
      <div>
        <div className="title">
          <TypeWriter text={"E-CARDS"} length={"short"} />
        </div>
        <div className="container is-fluid has-text-centered">
          <p className="block">
            <ThemeDropdown
              onChangeFunction={(e) => {
                if (e.target.value === "birthday") {
                  setShowCards("birthday");
                } else if (e.target.value === "thankyou") {
                  setShowCards("thankyou");
                } else if (e.target.value === "anniversary") {
                  setShowCards("anniversary");
                } else if (e.target.value === "graduation") {
                  setShowCards("graduation");
                } else if (e.target.value === "valentine") {
                  setShowCards("valentine");
                }
              }}
            />
          </p>
          <p className="block is-size-5-desktop is-size-6-mobile">
            Click the image of the card you would like to send!
          </p>
          <div className="tile is-ancestor has-text-centered">
            {showProperContent()}
          </div>
        </div>
        {isModalActive && (
          <CardModal
            template={templateName}
            deleteOnClick={() => {
              setIsModalActive(false);
            }}
          />
        )}
      </div>
    );
  };

  return <PageTemplate content={cardContent()} currentPage={"cards"} />;
};

export default Cards;
