import React from "react";

const BreadCrumbItem = ({icon, text}) => {
    return (
        <li>
            <a href='generate-two-people' className="disabled link">
            <span className="icon is-large is-light">
                <i className={`fas fa-solid ${icon}`}></i>
            </span>
            <span>{text}</span>
            </a>
        </li>
    )
}

export default BreadCrumbItem;