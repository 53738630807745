import { React, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbItem from "./BreadCrumbItem";
import FormFieldInput from "./FormFieldInput";
import FormFieldSelect from "./FormFieldSelect";
import FormFieldSelectSelected from "./FormFieldSelectSelected";

import "../../styles/style.css";

const FormTwoPeople = () => {
  const navigate = useNavigate();
  const params = useParams();

  // first person stuff
  const [firstPersonName, setFirstPersonName] = useState("");
  const [firstPersonBirthdate, setFirstPersonBirthdate] = useState("");
  const [firstPersonGender, setFirstPersonGender] = useState("");

  // second person stuff
  const [secondPersonName, setSecondPersonName] = useState("");
  const [secondPersonBirthdate, setSecondPersonBirthdate] = useState("");
  const [secondPersonGender, setSecondPersonGender] = useState("");

  // form stuff
  const [isFirstPerson, setIsFirstPerson] = useState(true);
  const [isSecondPerson, setIsSecondPerson] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    params.firstName = firstPersonName;
    params.secondName = secondPersonName;
    params.firstBirthdate = firstPersonBirthdate;
    params.secondBirthdate = secondPersonBirthdate;
    params.firstGender = firstPersonGender;
    params.secondGender = secondPersonGender;
    navigate(
      `/compatibility-${firstPersonName}-${firstPersonBirthdate}-${firstPersonGender}-${secondPersonName}-${secondPersonBirthdate}-${secondPersonGender}`
    );
  };

  const checkIfFirstPersonButtonDisabled = () => {
    if (
      firstPersonBirthdate === "" ||
      firstPersonName === "" ||
      firstPersonGender === "Select Gender" ||
      firstPersonGender === "" ||
      firstPersonBirthdate <= "1900-01-01" ||
      firstPersonBirthdate >= "2100-12-31"
    ) {
      return (
        <div className="field has-text-centered">
          <button
            className="button is-light is-medium is-responsive is-rounded"
            disabled
          >
            SUBMIT PERSON ONE
          </button>
        </div>
      );
    } else if (
      firstPersonName !== "" &&
      firstPersonGender !== "" &&
      firstPersonBirthdate !== ""
    ) {
      return (
        <div className="field has-text-centered">
          <button
            className="button is-light is-medium is-responsive is-rounded"
            onClick={() => {
              setIsFirstPerson(false);
              setIsSecondPerson(true);
            }}
          >
            SUBMIT PERSON ONE
          </button>
        </div>
      );
    }
  };

  const checkIfSecondPersonButtonDisabled = () => {
    if (
      secondPersonBirthdate === "" ||
      secondPersonName === "" ||
      secondPersonGender === "Select Gender" ||
      secondPersonGender === "" ||
      secondPersonBirthdate < "1900-01-01" ||
      secondPersonBirthdate > "2100-12-31"
    ) {
      return (
        <div className="field has-text-centered">
          <button
            className="button is-light is-responsive is-medium is-rounded"
            disabled
          >
            SUBMIT PERSON TWO
          </button>
        </div>
      );
    } else if (
      secondPersonName !== "" &&
      secondPersonGender !== "" &&
      secondPersonBirthdate !== ""
    ) {
      return (
        <div className="field has-text-centered">
          <button
            className="button is-light is-responsive is-medium  is-rounded"
            onClick={() => {
              setIsSecondPerson(false);
              setIsConfirm(true);
            }}
          >
            SUBMIT PERSON TWO
          </button>
        </div>
      );
    }
  };

  const displayConfirmButton = () => {
    if (isConfirm) {
      return (
        <div className="buttons is-centered is-responsive">
          <p className="control">
            <button
              className="button is-responsive is-medium is-light"
              id="go-back"
            >
              <span className="icon is-medium">
                <i className="fas fa-solid fa-xmark"></i>
              </span>
              <span>
                <a className="link" href="generate-two-people">
                  CANCEL
                </a>
              </span>
            </button>
          </p>
          <p className="control">
            <button
              className="button is-responsive is-medium is-light"
              id="confirm"
              onClick={onSubmit}
            >
              <span>CONFIRM</span>
              <span className="icon is-medium">
                <i className="fas fa-solid fa-check"></i>
              </span>
            </button>
          </p>
        </div>
      );
    }
  };

  const displayProperButton = () => {
    if (isFirstPerson) {
      return checkIfFirstPersonButtonDisabled();
    } else if (isSecondPerson) {
      return checkIfSecondPersonButtonDisabled();
    } else if (isConfirm) {
      return displayConfirmButton();
    }
  };

  const displayProperForm = () => {
    if (isFirstPerson) {
      return (
        <div>
          <FormFieldInput
            labelText={"FIRST PERSON NAME"}
            inputType={"text"}
            placeholder={"First Person's Name"}
            value={firstPersonName}
            onChangeFunction={(e) =>
              setFirstPersonName(
                e.target.value.replace(/[^a-z]/gi, "").toUpperCase()
              )
            }
          />

          <FormFieldInput
            labelText={"FIRST PERSON BIRTHDATE"}
            inputType={"date"}
            max={"2100-12-31"}
            min={"1900-01-01"}
            placeholder={"First Person's Birthdate"}
            value={firstPersonBirthdate}
            onChangeFunction={(e) => setFirstPersonBirthdate(e.target.value)}
          />

          <FormFieldSelect
            onChangeFunction={(e) => setFirstPersonGender(e.target.value)}
          />
        </div>
      );
    } else if (isSecondPerson) {
      return (
        <div>
          <FormFieldInput
            labelText={"SECOND PERSON NAME"}
            inputType={"text"}
            placeholder={"SECOND PERSON'S NAME"}
            value={secondPersonName}
            onChangeFunction={(e) =>
              setSecondPersonName(e.target.value.trim().toUpperCase())
            }
          />

          <FormFieldInput
            labelText={"SECOND PERSON BIRTHDATE"}
            inputType={"date"}
            max={"2100-12-31"}
            min={"1900-01-01"}
            placeholder={"SECOND PERSON'S BIRTHDATE"}
            value={secondPersonBirthdate}
            onChangeFunction={(e) => setSecondPersonBirthdate(e.target.value)}
          />

          <FormFieldSelectSelected
            onChangeFunction={(e) => setSecondPersonGender(e.target.value)}
          />
        </div>
      );
    } else if (isConfirm) {
      return (
        <div className="container has-text-centered">
          <div className="tile is-ancestor">
            <div className="tile is-parent">
              <article className="tile is-child notification is-dark blue">
                <p className="block">
                  <strong>USER ONE</strong>
                </p>
                <div className="content">
                  <p>
                    NAME: <strong>{firstPersonName}</strong>
                  </p>
                  <p>
                    BIRTHDATE: <strong>{firstPersonBirthdate}</strong>
                  </p>
                  <p>
                    GENDER: <strong>{firstPersonGender}</strong>
                  </p>
                </div>
              </article>
            </div>
            <div className="tile is-parent">
              <article className="tile is-child notification is-dark blue">
                <p className="block">
                  <strong>USER TWO</strong>
                </p>
                <div className="content">
                  <p>
                    NAME: <strong>{secondPersonName}</strong>
                  </p>
                  <p>
                    BIRTHDATE: <strong>{secondPersonBirthdate}</strong>
                  </p>
                  <p>
                    GENDER: <strong>{secondPersonGender}</strong>
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      );
    }
  };

  const displayProgress = () => {
    return (
      <nav className="breadcrumb is-large is-centered has-arrow-separator is-hidden-touch">
        <ul>
          <BreadCrumbItem
            icon={!isFirstPerson ? "fa-check" : "fa-spinner"}
            text={"First Person"}
          />
          <BreadCrumbItem
            icon={isConfirm ? "fa-check" : "fa-spinner"}
            text={"Second Person"}
          />
          <BreadCrumbItem
            icon={isConfirm ? "fa-check" : "fa-spinner"}
            text={"Confirmation"}
          />
        </ul>
      </nav>
    );
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      {displayProperForm()}
      &nbsp;
      {displayProperButton()}
      &nbsp;
      {displayProgress()}
    </form>
  );
};

export default FormTwoPeople;
