import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import vhCheck from "vh-check";

const PageTemplate = ({ content, currentPage, isChristmas }) => {
  // initiate states for each of the navbar options
  const [homeActive, setHomeActive] = useState();
  const [instructionsActive, setInstructionsActive] = useState();
  const [aboutActive, setAboutActive] = useState();
  const [supportActive, setSupportActive] = useState();
  const [navbarActive, setNavbarActive] = useState();

  // on initial load of page, make current page option in navbar active
  useEffect(() => {
    if (currentPage === "home") {
      setHomeActive(true);
      setInstructionsActive(false);
      setAboutActive(false);
      setSupportActive(false);
    } else if (currentPage === "instructions") {
      setHomeActive(false);
      setInstructionsActive(true);
      setAboutActive(false);
      setSupportActive(false);
    } else if (currentPage === "about") {
      setHomeActive(false);
      setInstructionsActive(false);
      setAboutActive(true);
      setSupportActive(false);
    } else if (currentPage === "support") {
      setSupportActive(true);
      setHomeActive(false);
      setInstructionsActive(false);
      setAboutActive(false);
    }
  }, [currentPage]);

  const showProperItems = () => {
    if (homeActive) {
      return (
        <div className="navbar-dropdown">
          <Link
            className={`navbar-item ${instructionsActive && "is-active"}`}
            to="/instructions"
          >
            Instructions
          </Link>
          <Link
            className={`navbar-item ${aboutActive && "is-active"}`}
            to="/about"
          >
            About
          </Link>
          <Link
            className={`navbar-item ${supportActive && "is-active"}`}
            to="/support"
          >
            Support
          </Link>
        </div>
      );
    } else if (instructionsActive) {
      return (
        <div className="navbar-dropdown">
          <Link className={`navbar-item ${homeActive && "is-active"}`} to="/">
            Home
          </Link>
          <Link
            className={`navbar-item ${aboutActive && "is-active"}`}
            to="/about"
          >
            About
          </Link>
          <Link
            className={`navbar-item ${supportActive && "is-active"}`}
            to="/support"
          >
            Support
          </Link>
        </div>
      );
    } else if (aboutActive) {
      return (
        <div className="navbar-dropdown">
          <Link className={`navbar-item ${homeActive && "is-active"}`} to="/">
            Home
          </Link>
          <Link
            className={`navbar-item ${instructionsActive && "is-active"}`}
            to="/instructions"
          >
            Instructions
          </Link>
          <Link
            className={`navbar-item ${supportActive && "is-active"}`}
            to="/support"
          >
            Support
          </Link>
        </div>
      );
    } else if (supportActive) {
      return (
        <div className="navbar-dropdown">
          <Link className={`navbar-item ${homeActive && "is-active"}`} to="/">
            Home
          </Link>
          <Link
            className={`navbar-item ${instructionsActive && "is-active"}`}
            to="/instructions"
          >
            Instructions
          </Link>
          <Link
            className={`navbar-item ${aboutActive && "is-active"}`}
            to="/about"
          >
            About
          </Link>
        </div>
      );
    } else {
      return (
        <div className="navbar-dropdown">
          <Link
            className={`navbar-item ${homeActive ? "is-active" : ""}`}
            to="/"
          >
            Home
          </Link>
          <Link
            className={`navbar-item ${instructionsActive ? "is-active" : ""}`}
            to="/instructions"
          >
            Instructions
          </Link>
          <Link
            className={`navbar-item ${aboutActive ? "is-active" : ""}`}
            to="/about"
          >
            About
          </Link>
          <Link
            className={`navbar-item ${supportActive && "is-active"}`}
            to="/support"
          >
            Support
          </Link>
        </div>
      );
    }
  };

  vhCheck();

  return (
    <div>
      <section
        className={`hero is-fullheight ${isChristmas ? "bg-christmas" : "bg"}`}
      >
        <div className="hero-head">
          <nav className="navbar is-transparent is-fixed-top">
            <div className="container">
              <div className="navbar-brand is-transparent">
                {!navbarActive ? (
                  <Link className="navbar-item" id="header-logo" to="/"></Link>
                ) : (
                  ""
                )}
                <span
                  onClick={() => {
                    navbarActive
                      ? setNavbarActive(false)
                      : setNavbarActive(true);
                  }}
                  className={`navbar-burger  ${
                    navbarActive ? "is-active" : ""
                  }`}
                >
                  <span className="burger"></span>
                  <span className="burger"></span>
                  <span className="burger"></span>
                </span>

                <div
                  className={`navbar-menu ${navbarActive ? "is-active" : ""}`}
                  onClick={() => {
                    setNavbarActive(true);
                  }}
                >
                  {navbarActive ? showProperItems() : ""}
                </div>
              </div>

              <div className="navbar-menu">
                <div className="navbar-end">
                  <Link
                    className={`navbar-item not-burger ${
                      homeActive ? "is-active" : ""
                    }`}
                    to="/"
                    onClick={() => {
                      setHomeActive(true);
                      setInstructionsActive(false);
                      setAboutActive(false);
                      setSupportActive(false);
                    }}
                  >
                    Home
                  </Link>
                  <Link
                    className={`navbar-item not-burger ${
                      instructionsActive ? "is-active" : ""
                    }`}
                    to="/instructions"
                    onClick={() => {
                      setInstructionsActive(true);
                      setHomeActive(false);
                      setAboutActive(false);
                      setSupportActive(false);
                    }}
                  >
                    Instructions
                  </Link>
                  <Link
                    className={`navbar-item not-burger ${
                      aboutActive ? "is-active" : ""
                    }`}
                    to="/about"
                    onClick={() => {
                      setAboutActive(true);
                      setHomeActive(false);
                      setInstructionsActive(false);
                      setSupportActive(false);
                    }}
                  >
                    About
                  </Link>
                  <Link
                    className={`navbar-item not-burger ${
                      supportActive ? "is-active" : ""
                    }`}
                    to="/support"
                    onClick={() => {
                      setSupportActive(true);
                      setAboutActive(false);
                      setHomeActive(false);
                      setInstructionsActive(false);
                    }}
                  >
                    Support
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>

        <div className="hero-body">
          <div className="container has-text-centered">{content}</div>
        </div>

        <div className="hero-foot">
          <div className="container has-text-centered">Copyright - 2022</div>
        </div>
      </section>
    </div>
  );
};

export default PageTemplate;
