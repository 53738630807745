import React from "react";

const CompletedForm = () => {
  return (
    <div className="email-field has-text-centered">
      <div className="field">
        <h1 className="label">Your virtual card has been sent successfully!</h1>
        <h1 className="label">A copy has also been sent to your email!</h1>
        <img src={require("../images/logo.png")} />
        <h1 className="label">
          Happy Melody Making from Birthday Melody Match!
        </h1>
      </div>
    </div>
  );
};

export default CompletedForm;
