import React from 'react';

const MessageWithDelete = ({message, messageContent, deleteOnClick}) => {
    return (
        <article className='message is-dark is-medium'>
            <div className='message-header'>
                {message}
                <button className='delete' onClick={deleteOnClick}></button>  
            </div>
            <div className='message-body has-text-left'>{messageContent}</div>
        </article>
    )
}

export default MessageWithDelete;