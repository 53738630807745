import React from "react";
import MessageWithDelete from "./MessageWithDelete";
import CardForm from "../Form/CardForm";

const CardModal = ({ deleteOnClick, template }) => {
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <MessageWithDelete
          message={"SEND YOUR CARD"}
          messageContent={<CardForm template={template} />}
          deleteOnClick={deleteOnClick}
        />
      </div>
    </div>
  );
};

export default CardModal;
