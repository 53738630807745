import React from "react";
import PageTemplate from "../Components/PageTemplate";
import TypeWriter from "../Components/TypeWriter";
import RoundedIconButton from "../Components/Buttons/RoundedIconButton";

const Instructions = () => {
  const instructionContent = () => {
    return (
      <div>
        <div className="title">
          <TypeWriter text={"INSTRUCTIONS"} length={"short"} />
        </div>
        <div className="container is-fluid has-text-centered">
          <p className="block is-size-5-desktop is-size-6-mobile">
            Welcome to <strong>Birthday Melody Match</strong>! The instructions
            are simple - pick whether you want to generate a melody for one
            person or for two, enter some <strong>basic information</strong>,
            and then, within seconds, our musical genius behind the scenes will
            generate a <strong>unique melody</strong> based off the information
            you provided! Once your melody is generated, you will be able to
            choose from{" "}
            <strong>
              different instruments, share with friends and family,
            </strong>{" "}
            and even <strong>save</strong> it to your device! Our two person
            option allows you to <strong>generate compatibility</strong> based
            on how much your melodies match! Click the button below to start
            generating!
          </p>
          <RoundedIconButton
            icon={"fa-circle-arrow-right"}
            text={"Get Started"}
            whereToNavigate={"generate"}
          />
        </div>
      </div>
    );
  };

  return (
    <PageTemplate content={instructionContent()} currentPage={"instructions"} />
  );
};

export default Instructions;
