import React, { useState } from "react";
import MessageWithDelete from "./MessageWithDelete";
import {
  getFirstDigitPercentage,
  getSecondFourthSeventhEigthPercentage,
  getThirdDigitPercentage,
  getFifthDigitPercentage,
  getSixthDigitPercentage,
  checkSamAndLola,
  checkRichardAndLola,
} from "../../functions/getPercentages";

const CompatibilityModal = ({ deleteOnClick }) => {
  const [value, setValue] = useState(null);
  const [loading, setIsLoading] = useState(true);

  let m1, m2, m3, m4, m5, m6, m7, m8, f1, f2, f3, f4, f5, f6, f7, f8;

  let pathname = window.location.pathname;
  pathname = pathname.split("-");
  pathname.shift();
  if (pathname[4] === "Female") {
    f1 = pathname[3][0];
    f2 = pathname[3][1];
    f3 = pathname[2][0];
    f4 = pathname[2][1];
    f5 = pathname[1][0];
    f6 = pathname[1][1];
    f7 = pathname[1][2];
    f8 = pathname[1][3];
    m1 = pathname[8][0];
    m2 = pathname[8][1];
    m3 = pathname[7][0];
    m4 = pathname[7][1];
    m5 = pathname[6][0];
    m6 = pathname[6][1];
    m7 = pathname[6][2];
    m8 = pathname[6][3];
  } else {
    m1 = pathname[3][0];
    m2 = pathname[3][1];
    m3 = pathname[2][0];
    m4 = pathname[2][1];
    m5 = pathname[1][0];
    m6 = pathname[1][1];
    m7 = pathname[1][2];
    m8 = pathname[1][3];
    f1 = pathname[8][0];
    f2 = pathname[8][1];
    f3 = pathname[7][0];
    f4 = pathname[7][1];
    f5 = pathname[6][0];
    f6 = pathname[6][1];
    f7 = pathname[6][2];
    f8 = pathname[6][3];
  }

  // const checkSpecialCase = () => {
  //   let isSamAndLola = checkSamAndLola(
  //     pathname[0],
  //     pathname[3],
  //     pathname[2],
  //     pathname[1],
  //     pathname[4],
  //     pathname[5],
  //     pathname[8],
  //     pathname[7],
  //     pathname[6],
  //     pathname[9]
  //   );

  //   return isSamAndLola;
  // };

  let isSamAndLola = checkSamAndLola(
    pathname[0],
    pathname[3],
    pathname[2],
    pathname[1],
    pathname[4],
    pathname[5],
    pathname[8],
    pathname[7],
    pathname[6],
    pathname[9]
  );

  let isRichardAndLola = checkRichardAndLola(
    pathname[0],
    pathname[3],
    pathname[2],
    pathname[1],
    pathname[4],
    pathname[5],
    pathname[8],
    pathname[7],
    pathname[6],
    pathname[9]
  );

  const getFinalPercentages = () => {
    let percentage =
      getFirstDigitPercentage(m1, f1) +
      getSecondFourthSeventhEigthPercentage(m2, f2) +
      getThirdDigitPercentage(m3, f3) +
      getSecondFourthSeventhEigthPercentage(m4, f4) +
      getFifthDigitPercentage(m5, f5) +
      getSixthDigitPercentage(m6, f6) +
      getSecondFourthSeventhEigthPercentage(m7, f7) +
      getSecondFourthSeventhEigthPercentage(m8, f8);
    return percentage >= 90 ? (percentage = 100) : percentage + 8;
  };

  const setMessage = () => {
    let message = "";
    if (value === 100) {
      message = "OMG! A perfect match!!";
    }
    if (value >= 150) {
      message = "I HAVE NEVER SEEN SOMETHING SO PERFECT! <3";
    } else if (value >= 90) {
      message = "WOW! You guys get along really well :)";
    } else if (value >= 80) {
      message = "You guys work pretty well together :)";
    } else {
      message = "You guys work good together :)";
    }

    return message;
  };

  const messageContent = () => {
    return (
      <div>
        {loading ? (
          <div className="has-text-centered modal-text">
            YOU TWO ARE ... % COMPATIBLE!
          </div>
        ) : (
          <div className="has-text-centered modal-text">
            YOU TWO ARE &nbsp;<strong className="is-size-3">{value}% </strong>
            COMPATIBLE!
          </div>
        )}
        &nbsp;
        {value ? (
          <div>
            <progress
              className="progress is-medium is-danger"
              value={value}
              max="100"
            >
              {value}%
            </progress>
            <div className="has-text-centered modal-text">{setMessage()}</div>
          </div>
        ) : (
          <div>
            <progress className="progress is-danger" max="100"></progress>
            &nbsp;
          </div>
        )}
      </div>
    );
  };

  setTimeout(() => {
    if (isRichardAndLola) {
      setValue(151);
    } else if (isSamAndLola) {
      setValue(150);
    } else {
      setValue(getFinalPercentages());
    }
    // checkSpecialCase() ? setValue(150) : setValue(getFinalPercentages());
    setIsLoading(false);
  }, 4500);

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <MessageWithDelete
          message={"COMPATIBILITY"}
          messageContent={messageContent()}
          deleteOnClick={deleteOnClick}
        />
      </div>
    </div>
  );
};

export default CompatibilityModal;
