import React from "react";
import PageTemplate from "../../Components/PageTemplate";
import TypeWriter from "../../Components/TypeWriter";
import LeftHoverButton from "../../Components/Buttons/LeftHoverButton";
import RightHoverButton from "../../Components/Buttons/RightHoverButton";

const Generate = () => {
  const generateContent = () => {
    return (
      <div>
        <div className="title">
          <TypeWriter text={"How Many Users?"} length={"short"} />
        </div>
        <div className="buttons has-addons is-centered">
          <LeftHoverButton
            icon={"fa-user"}
            text={"One Person"}
            whereToNavigate={"generate-one-person"}
          />
          <button className="button is-medium is-responsive is-static is-rounded">
            OR
          </button>
          <RightHoverButton
            icon={"fa-user-group"}
            text={"Two People"}
            whereToNavigate={"generate-two-people"}
          />
        </div>
      </div>
    );
  };

  return <PageTemplate content={generateContent()} currentPage={"generate"} />;
};

export default Generate;
