import React from "react";

const SendEmailButton = ({ text, icon }) => {
  return (
    <div className="field">
      <p className="control">
        <button className="button email-submit is-rounded">
          <span className="icon is-small">
            <i className={icon}></i>
          </span>
          <span>{text}</span>
        </button>
      </p>
    </div>
  );
};

export default SendEmailButton;
