import React from "react";

const BuyMeACoffee = () => {
  return (
    <a href="https://www.buymeacoffee.com/bdaymelodymatch">
      <img src="https://img.buymeacoffee.com/button-api/?text=Buy Us a Coffee&emoji=&slug=bdaymelodymatch&button_colour=FF5F5F&font_colour=ffffff&font_family=Poppins&outline_colour=000000&coffee_colour=FFDD00" />
    </a>
  );
};

export default BuyMeACoffee;
