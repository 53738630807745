import React from "react";
import PageTemplate from "../Components/PageTemplate";
import TypeWriter from "../Components/TypeWriter";
import RoundedIconButton from "../Components/Buttons/RoundedIconButton";
import BrandIcons from "../Components/BrandIcons";

const About = () => {
  const aboutContent = () => {
    return (
      <div>
        <div className="title">
          <TypeWriter text={"ABOUT"} length={"short"} />
        </div>
        <div className="container is-fluid has-text-centered">
          <p className="block is-size-5-desktop is-size-6-mobile">
            <strong>Birthday Melody Match</strong> was created as a way to{" "}
            <strong>bring people together</strong> through music. With eight
            numbers in a <strong>birthdate</strong> and eight notes in a{" "}
            <strong>musical scale</strong>, our music genius behind the scenes
            came up with a pattern for each number to have a{" "}
            <strong>unique note</strong> assigned to it. This way, no matter
            what combination of numbers you have for your birthdate, you'll have
            a <strong>really cool sounding</strong> melody! The compatibility
            percentage for our <strong>two person option</strong> is based on
            how well your notes match! Visit us at any of our{" "}
            <strong>social medias</strong> below!
          </p>
          <p className="block is-size-5-desktop is-size-6-mobile">
            <BrandIcons
              iconName={"fa-instagram"}
              link={"https://instagram.com/birthdaymelodymatch"}
            />
            <BrandIcons
              iconName={"fa-twitter"}
              link={"https://twitter.com/bdaymelodymatch"}
            />
            <BrandIcons
              iconName={"fa-tiktok"}
              link={"https://www.tiktok.com/@birthdaymelodymatch"}
            />
          </p>
          <div className="block has-text-centered">
            <RoundedIconButton
              icon={"fa-circle-arrow-right"}
              text={"Get Started"}
              whereToNavigate={"generate"}
            />
          </div>
        </div>
      </div>
    );
  };

  return <PageTemplate content={aboutContent()} currentPage={"about"} />;
};

export default About;
