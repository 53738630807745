import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./Pages/HomePage";
import Instructions from "./Pages/Instructions";
import About from "./Pages/About";
import Generate from "./Pages/Generate/Generate";
import GenerateOnePerson from "./Pages/Generate/GenerateOnePerson";
import GenerateTwoPeople from "./Pages/Generate/GenerateTwoPeople";
import MelodyOneUser from "./Pages/Melody/MelodyOneUser";
import MelodyTwoUsers from "./Pages/Melody/MelodyTwoUsers";
import Support from "./Pages/Support";
import Cards from "./Pages/Cards";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Countdown />} /> */}
          <Route path="/" element={<HomePage />} />
          <Route path="/instructions" element={<Instructions />} />
          <Route path="/about" element={<About />} />
          <Route path="/support" element={<Support />} />
          <Route path="/generate" element={<Generate />} />
          <Route path="/generate-one-person" element={<GenerateOnePerson />} />
          <Route path="/generate-two-people" element={<GenerateTwoPeople />} />
          <Route
            path="/melody-:name-:birthdate-:gender"
            element={<MelodyOneUser />}
          />
          <Route
            path="/compatibility-:firstName-:firstBirthdate-:firstGender-:secondName-:secondBirthdate-:secondGender"
            element={<MelodyTwoUsers />}
          />
          <Route path="/cards" element={<Cards />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
