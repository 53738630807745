import React from "react";

const TypeWriter = ({ text, length }) => {
  const getLength = () => {
    if (length === "long") {
      return "long";
    } else {
      return "short";
    }
  };

  return (
    <div className="typewriter-container">
      <div className="typewriter is-size-1 is-size-3-mobile" id={getLength()}>
        {text}&nbsp;
      </div>
    </div>
  );
};

export default TypeWriter;
