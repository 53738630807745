export const getFirstNote = (firstDigitDate, gender) => {
  let note = "";
  if (gender === "Male") {
    switch (firstDigitDate) {
      case "0":
        note = "C4";
        break;
      case "1":
        note = "D4";
        break;
      case "2":
        note = "E4";
        break;
      case "3":
        note = "F4";
        break;
      default:
        note = "C4";
    }
  } else {
    switch (firstDigitDate) {
      case "0":
        note = "G4";
        break;
      case "1":
        note = "A4";
        break;
      case "2":
        note = "B4";
        break;
      case "3":
        note = "C5";
        break;
      default:
        note = "G4";
    }
  }

  return note;
};

export const getSecondFourthSeventhEighthNote = (digit) => {
  let note = "";
  switch (digit) {
    case "0":
      note = "C4";
      break;
    case "1":
      note = "D4";
      break;
    case "2":
      note = "E4";
      break;
    case "3":
      note = "F4";
      break;
    case "4":
      note = "G4";
      break;
    case "5":
      note = "A4";
      break;
    case "6":
      note = "B4";
      break;
    case "7":
      note = "C5";
      break;
    case "8":
      note = "D5";
      break;
    case "9":
      note = "E5";
      break;
    default:
      note = "C4";
  }

  return note;
};

export const getThirdNote = (firstDigitMonth, gender) => {
  let note = "";
  if (gender === "Male") {
    switch (firstDigitMonth) {
      case "0":
        note = "C4";
        break;
      case "1":
        note = "E4";
        break;
      default:
        note = "C4";
    }
  } else {
    switch (firstDigitMonth) {
      case "0":
        note = "G4";
        break;
      case "1":
        note = "A4";
        break;
      default:
        note = "G4";
    }
  }

  return note;
};

export const getFifthNote = (firstDigitYear, gender) => {
  let note = "";
  if (gender === "Male") {
    switch (firstDigitYear) {
      case "1":
        note = "G4";
        break;
      case "2":
        note = "A4";
        break;
      default:
        note = "G4";
    }
  } else {
    switch (firstDigitYear) {
      case "1":
        note = "B4";
        break;
      case "2":
        note = "C5";
        break;
      default:
        note = "B4";
    }
  }

  return note;
};

export const getSixthNote = (secondDigitYear, gender) => {
  let note = "";
  if (gender === "Male") {
    switch (secondDigitYear) {
      case "0":
        note = "G4";
        break;
      case "9":
        note = "A4";
        break;
      default:
        note = "G4";
    }
  } else {
    switch (secondDigitYear) {
      case "0":
        note = "B4";
        break;
      case "9":
        note = "C5";
        break;
      default:
        note = "B4";
    }
  }
  return note;
};

// same year
export const getFirstDigitYearNote = (firstDigitYear, gender) => {
  let note = "";
  if (gender === "Male") {
    switch (firstDigitYear) {
      case "1":
        note = "G5";
        break;
      case "2":
        note = "A5";
        break;
      default:
        note = "G5";
    }
  } else {
    switch (firstDigitYear) {
      case "1":
        note = "B5";
        break;
      case "2":
        note = "C6";
        break;
      default:
        note = "B5";
    }
  }
  return note;
};

export const getSecondDigitYearNote = (secondDigitYear, gender) => {
  let note = "";
  if (gender === "Male") {
    switch (secondDigitYear) {
      case "0":
        note = "G5";
        break;
      case "9":
        note = "A5";
        break;
      default:
        note = "G5";
    }
  } else {
    switch (secondDigitYear) {
      case "0":
        note = "G6";
        break;
      case "9":
        note = "A6";
        break;
      default:
        note = "G6";
    }
  }
  return note;
};

export const getThirdFourthDigitYearNote = (digit) => {
  let note = "";
  switch (digit) {
    case "0":
      note = "C5";
      break;
    case "1":
      note = "D5";
      break;
    case "2":
      note = "E5";
      break;
    case "3":
      note = "F5";
      break;
    case "4":
      note = "G5";
      break;
    case "5":
      note = "A5";
      break;
    case "6":
      note = "B5";
      break;
    case "7":
      note = "C6";
      break;
    case "8":
      note = "D6";
      break;
    case "9":
      note = "E6";
      break;
    default:
      note = "C5";
  }
  return note;
};
