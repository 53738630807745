import React from "react";
import { useNavigate } from "react-router-dom";

const RoundedIconButton = ({ icon, text, whereToNavigate, isDisabledBool }) => {
  const navigate = useNavigate();

  return (
    <button
      className={`button is-light is-responsive is-medium is-rounded`}
      onClick={() => navigate(`/${whereToNavigate}`)}
      disabled={isDisabledBool}
    >
      <span className="icon">
        <i className={`fas ${icon}`}></i>
      </span>
      <span>{text}</span>
    </button>
  );
};

export default RoundedIconButton;
