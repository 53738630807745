import React from "react";

const TextBox = ({ label, placeholder, name, value, onChangeFunction }) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <textarea
        name={name}
        className="textarea"
        value={value}
        rows="2"
        placeholder={placeholder}
        onChange={onChangeFunction}
      ></textarea>
    </div>
  );
};

export default TextBox;
