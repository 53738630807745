import React from "react";

const NameField = ({
  label,
  inputType,
  placeholder,
  maxLength,
  value,
  name,
  onChangeFunction,
  onKeyUpFunction,
  icon,
}) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <p className="control has-icons-left">
        <input
          className="input"
          type={inputType}
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          name={name}
          onChange={onChangeFunction}
          onKeyUp={onKeyUpFunction}
          required
        />
        <span className="icon is-small is-left">
          <i className={icon}></i>
        </span>
      </p>
    </div>
  );
};

export default NameField;
