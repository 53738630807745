import { React, useState } from "react";
import SoundFontPlayer from "soundfont-player";
import RoundedIconButtonNoNav from "../Buttons/RoundedIconButtonNoNav";
import RoundedIconButton from "../Buttons/RoundedIconButton";
import ShareModal from "../Modal/ShareModal";
import {
  getFirstNote,
  getSecondFourthSeventhEighthNote,
  getThirdNote,
  getFifthNote,
  getSixthNote,
  getFirstDigitYearNote,
  getSecondDigitYearNote,
  getThirdFourthDigitYearNote,
} from "../../functions/twoUserMusic";

const PlayMusicTwo = ({ instrumentName }) => {
  let ac = new AudioContext();
  const mediaStreamDestination = ac.createMediaStreamDestination();
  const mediaRecorder = new MediaRecorder(mediaStreamDestination.stream);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [blobURL, setBlobURL] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const chunks = [];
  let url;

  let pathname = window.location.pathname;
  let new_pathname = pathname.split("-");
  new_pathname.shift();
  let user1_firstName = new_pathname[0];
  let user2_firstName = new_pathname[5];
  let birthyear_user1 = new_pathname[1];
  let birthmonth_user1 = new_pathname[2];
  let birthdate_user1 = new_pathname[3];
  let gender_user1 = new_pathname[4];
  let birthyear_user2 = new_pathname[6];
  let birthmonth_user2 = new_pathname[7];
  let birthdate_user2 = new_pathname[8];
  let gender_user2 = new_pathname[9];

  // FIRST USER
  let firstDigitDate_1 = birthdate_user1[0];
  let secondDigitDate_1 = birthdate_user1[1];
  let firstDigitMonth_1 = birthmonth_user1[0];
  let secondDigitMonth_1 = birthmonth_user1[1];
  let firstDigitYear_1 = birthyear_user1[0];
  let secondDigitYear_1 = birthyear_user1[1];
  let thirdDigitYear_1 = birthyear_user1[2];
  let fourthDigitYear_1 = birthyear_user1[3];

  // SECOND USER
  let firstDigitDate_2 = birthdate_user2[0];
  let secondDigitDate_2 = birthdate_user2[1];
  let firstDigitMonth_2 = birthmonth_user2[0];
  let secondDigitMonth_2 = birthmonth_user2[1];
  let firstDigitYear_2 = birthyear_user2[0];
  let secondDigitYear_2 = birthyear_user2[1];
  let thirdDigitYear_2 = birthyear_user2[2];
  let fourthDigitYear_2 = birthyear_user2[3];

  let firstLength_1,
    secondLength_1,
    thirdLength_1,
    fourthLength_1,
    fifthLength_1,
    sixthLength_1,
    seventhLength_1,
    eighthLength_1,
    firstLength_2,
    secondLength_2,
    thirdLength_2,
    fourthLength_2,
    fifthLength_2,
    sixthLength_2,
    seventhLength_2,
    eighthLength_2 = "";

  if (gender_user1 === gender_user2) {
    gender_user1 = "Male";
    gender_user2 = "Female";
  }

  const checkISameBirthYear = () => {
    if (birthyear_user1 === birthyear_user2) {
      return true;
    }
  };

  let fifthNote_2 = checkISameBirthYear()
    ? getFirstDigitYearNote(firstDigitYear_2, gender_user2)
    : getFifthNote(firstDigitYear_2, gender_user2);

  let sixthNote_2 = checkISameBirthYear()
    ? getSecondDigitYearNote(secondDigitYear_2, gender_user2)
    : getSixthNote(secondDigitYear_2, gender_user2);

  let seventhNote_2 = checkISameBirthYear()
    ? getThirdFourthDigitYearNote(thirdDigitYear_2)
    : getSecondFourthSeventhEighthNote(thirdDigitYear_2);

  let eighthNote_2 = checkISameBirthYear()
    ? getThirdFourthDigitYearNote(fourthDigitYear_2)
    : getSecondFourthSeventhEighthNote(fourthDigitYear_2);

  const noteLength = () => {
    if ((gender_user1 || gender_user2) === "Female") {
      firstLength_1 =
        secondLength_1 =
        fifthLength_1 =
        sixthLength_1 =
        firstLength_2 =
        secondLength_2 =
        fifthLength_2 =
        sixthLength_2 =
          1;
      seventhLength_1 = seventhLength_2 = 2;
      thirdLength_1 = thirdLength_2 = 1.5;
      fourthLength_1 = fourthLength_2 = 0.5;
      eighthLength_1 = eighthLength_2 = 3;
    } else if ((gender_user1 || gender_user2) === "Male") {
      firstLength_1 = firstLength_2 = 1.5;
      secondLength_1 = secondLength_2 = fifthLength_1 = fifthLength_2 = 0.5;
      thirdLength_1 =
        fourthLength_1 =
        sixthLength_1 =
        thirdLength_2 =
        fourthLength_2 =
        sixthLength_2 =
          1;
      seventhLength_1 = seventhLength_2 = 2.5;
      eighthLength_1 = eighthLength_2 = 3;
    }
  };

  const showDownloadButton = ({ instrumentName }) => {
    return (
      <a
        href={blobURL}
        className="link"
        download={`${user1_firstName}_${user2_firstName}_${instrumentName}_melody.aac`}
      >
        <button
          className="button is-medium is-responsive is-light is-rounded"
          disabled={isButtonDisabled}
        >
          <span className="icon">
            <i className={`fas fa-download`}></i>
          </span>
          <span>SAVE</span>
        </button>
      </a>
    );
  };

  return (
    <div>
      <button
        className={`button is-light is-fullwidth is-responsive is-rounded ${
          isLoading ? "is-loading" : ""
        }`}
        onClick={() => {
          ac.resume();
          mediaRecorder.start(100);
          setIsLoading(true);
          setIsButtonDisabled(true);
          noteLength();
          SoundFontPlayer.instrument(ac, `${instrumentName}`).then(function (
            instrument
          ) {
            instrument.connect(mediaStreamDestination);
            instrument.schedule(ac.currentTime, [
              {
                time: 0,
                duration: firstLength_1,
                note: getFirstNote(firstDigitDate_1, gender_user1),
              },
              {
                time: 0,
                duration: firstLength_2,
                note: getFirstNote(firstDigitDate_2, gender_user2),
              },
              {
                time: 1.5,
                duration: secondLength_1,
                note: getSecondFourthSeventhEighthNote(secondDigitDate_1),
              },
              {
                time: 1,
                duration: secondLength_2,
                note: getSecondFourthSeventhEighthNote(secondDigitDate_2),
              },
              {
                time: 2,
                duration: thirdLength_1,
                note: getThirdNote(firstDigitMonth_1, gender_user1),
              },
              {
                time: 2,
                duration: thirdLength_2,
                note: getThirdNote(firstDigitMonth_2, gender_user2),
              },
              {
                time: 3,
                duration: fourthLength_1,
                note: getSecondFourthSeventhEighthNote(secondDigitMonth_1),
              },
              {
                time: 3.5,
                duration: fourthLength_2,
                note: getSecondFourthSeventhEighthNote(secondDigitMonth_2),
              },
              {
                time: 4,
                duration: fifthLength_1,
                note: getFifthNote(firstDigitYear_1, gender_user1),
              },
              {
                time: 4,
                duration: fifthLength_2,
                note: fifthNote_2,
              },
              {
                time: 4.5,
                duration: sixthLength_1,
                note: getSixthNote(secondDigitYear_1, gender_user1),
              },
              {
                time: 5,
                duration: sixthLength_2,
                note: sixthNote_2,
              },
              {
                time: 5.5,
                duration: seventhLength_1,
                note: getSecondFourthSeventhEighthNote(thirdDigitYear_1),
              },
              {
                time: 6,
                duration: seventhLength_2,
                note: seventhNote_2,
              },
              {
                time: 8,
                duration: eighthLength_1,
                note: getSecondFourthSeventhEighthNote(fourthDigitYear_1),
              },
              {
                time: 8,
                duration: eighthLength_2,
                note: eighthNote_2,
              },
            ]);

            mediaRecorder.addEventListener("dataavailable", (e) => {
              chunks.push(e.data);
            });

            setTimeout(() => {
              if (mediaRecorder.state === "inactive") return;
              mediaRecorder.stop();
              url = URL.createObjectURL(new Blob(chunks));
              setBlobURL(url);
              setIsLoading(false);
              setIsButtonDisabled(false);
            }, 11000);
          });
        }}
      >
        <span className="icon">
          <i className="fas fa-solid fa-play"></i>
        </span>
        <span>PLAY MELODY</span>
      </button>
      &nbsp;
      <div className="buttons is-centered">
        <RoundedIconButtonNoNav
          icon={"fa-share-nodes"}
          text={"SHARE"}
          onClickFunction={() => {
            setIsModalActive(true);
          }}
          isDisabledBool={isButtonDisabled}
        />
        {showDownloadButton({ instrumentName })}
        <RoundedIconButton
          icon="fa-solid fa-arrows-rotate"
          text="New Melody"
          whereToNavigate={"generate"}
          isDisabledBool={isButtonDisabled}
        />
      </div>
      {isModalActive && (
        <ShareModal deleteOnClick={() => setIsModalActive(false)} />
      )}
    </div>
  );
};

export default PlayMusicTwo;
