import React, { useState } from "react";
import MessageWithDelete from "./MessageWithDelete";
import ShareIcons from "../../ShareIcons";
import { Simplesharer } from "simple-sharer";

const ShareModal = ({ deleteOnClick }) => {
  const [copyMessage, setCopyMessage] = useState(false);
  let message = "Link copied to the clipboard :)";

  const sharer = new Simplesharer();
  sharer.url = "https://www.birthdaymelodymatch.com";
  sharer.title = "Birthday Melody Match";
  sharer.text =
    "Birthday Melody Match just created my birthday melody! Make yours today!";
  sharer.hashtags = ["birthdaymelodymatch", "melody", "music"];

  const messageContent = () => {
    return (
      <div className="has-text-centered">
        <div>
          <p className="modal-text">
            Share <strong>Birthday Melody Match</strong> with your loved ones!
          </p>
          &nbsp;
          <p className="block is-size-5-desktop is-size-6-mobile">
            <ShareIcons
              iconName={"fa-brands fa-facebook"}
              onClickFunction={() => sharer.facebook()}
            />
            <ShareIcons
              iconName={"fa-brands fa-twitter"}
              onClickFunction={() => sharer.twitter()}
            />
            <ShareIcons
              iconName={"fa-brands fa-reddit"}
              onClickFunction={() => sharer.reddit()}
            />
            <ShareIcons
              iconName={"fa-brands fa-linkedin"}
              onClickFunction={() => sharer.linkedin()}
            />
            <ShareIcons
              iconName={"fa-solid fa-link"}
              onClickFunction={() => {
                sharer.copy();
                setCopyMessage(true);
                setTimeout(() => {
                  setCopyMessage(false);
                }, 3000);
              }}
            />
          </p>
          {copyMessage ? <p className="modal-text">{message}</p> : <p> </p>}
        </div>
      </div>
    );
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <MessageWithDelete
          message={"SHARE"}
          messageContent={messageContent()}
          deleteOnClick={deleteOnClick}
        />
      </div>
    </div>
  );
};

export default ShareModal;
