import React from "react";

const BrandIcons = ({ iconName, link }) => {
  return (
    <span className="icon is-info clickable is-large">
      <a href={link} target="_blank">
        <i className={`fa-brands ${iconName} fa-2x`}></i>
      </a>
    </span>
  );
};

export default BrandIcons;
