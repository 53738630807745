import React from "react";

const ImageTiles = ({ folderName, imageSrc, onClickFunction }) => {
  return (
    <div onClick={onClickFunction} className="tile is-parent">
      <div className="tile is-child">
        <figure className="image is-fullwidth is-clickable">
          <img src={require(`../images/${folderName}/${imageSrc}`)} />
        </figure>
      </div>
    </div>
  );
};

export default ImageTiles;
